<template>
  <!--  提示确认框  -->
  <el-dialog
    :visible.sync="tipDialog"
    custom-class="tipDialog"
    :show-close="false"
    :width="`${width}px`"
    @close="close()"
    top="35vh"
    center>
    <div id="tipDialog">
      <div class="title" v-if="title">{{ title }}</div>
      <div class="text">
        {{ text }}
      </div>
      <slot name="btn">
        <div class="btn" @click="btnFuc">{{ btnText }}</div>
      </slot>
      <img class="close" src="@/assets/image/AccountDeal/close.png" alt="" @click="close()">
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "mydialog",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    title:{
      type:String,
      default:''
    },
    text:{
      type:String,
      default:''
    },
    btn:{
      type:Boolean,
      default:false
    },
    btnGroup:{
      type:Boolean,
      default:false
    },
    btnText:{
      type:String,
      default:''
    },
    btnFuc:{
      type:Function,
      default:null
    },
    width:{
      type:String,Number,
      default:'358'
    },
    closeFun:{
      type:Function,
      default:null
    }
  },
  data(){
    return {
      tipDialog:this.show
    }
  },
  methods:{
    close(){
      this.closeFun()
    }
  },
  watch:{
    show(val){
      this.tipDialog = val
    }
  }


}
</script>

<style lang="less" scoped>
/deep/.el-dialog__header,/deep/.el-dialog__body {
  padding: 0 !important;
}
/deep/.tipDialog {
  border-radius: 10px;
  #tipDialog {
    position: relative;
    padding: 26px 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    .title {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #353535;
    }

    .text {
      width: 289px;
      margin: 22px 0;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #696969;
      text-align: center;
    }

    .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #000000;
      background: #ffdb51;
      border-radius: 21px;
      cursor: pointer;
    }

    .close {
      position: absolute;
      width: 30px;
      height: 30px;
      bottom: -40px;
      left: calc(50% - 15px);
      cursor: pointer;
    }
  }
}
</style>

