<template>
  <div class="online_deal">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'AllGame'}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;全部游戏
      </router-link>
      <router-link :to="{name:'SelectGame',query:{game_id:this.$route.query.game_id}}">&emsp;<span class="Breadcrumb"><i
        class="el-icon-arrow-right "></i></span>&emsp;{{ data.game_name }}
      </router-link>
      <router-link
        :to="this.$route.query.game_id == '26'?{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'yuanshen5'}}:{name:'SelectGame',query:{game_id:this.$route.query.game_id,game_alias:'games'}}">
        &emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;{{ data.game_name }}
      </router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;提交订单</a>
    </div>
    <div class="content">
      <div class="steps">
        <div id="steps">
          <el-steps align-center :active="active">
            <el-step title="提交订单"></el-step>
            <el-step title="确认支付"></el-step>
            <el-step title="平台发货"></el-step>
            <el-step title="交易完成"></el-step>
          </el-steps>
        </div>
      </div>
      <div class="step1" v-if="active===0">
        <div class="product_info">
          <div class="product_info_title">
            <span></span>
            商品信息
          </div>
          <div class="product_info_content">
            <div id="product_info_content">
              <div class="details_img">
                <!--              <img src="../../../assets/image/AllGame/lolTest.png" alt="">-->
                <img :src="data.image" alt="">
              </div>
              <div class="details_info">
                <div class="details_info_top">
                  <span>{{ data.game_name }}</span>
                  {{ data.name }}
                  <div id="game_list_details_content">
                    <div id="game_list_details_content_title">商品详情</div>
                    <div id="game_list_details_content_detail">
                      {{ data.name }}
                    </div>
                  </div>
                </div>
                <div class="details_info_mid">
                <span v-for="(categoryItem,index) in data.category" :key="index">
                  {{ categoryItem.name.toString().slice(1, categoryItem.name.toString().length) }}:
                  {{ categoryItem.value }}
                </span>
                </div>
                <div class="details_info_bot">
                  <span class="release_time">发布时间：{{ data.up_time|moment }}</span>
                  <span class="hot_pic">热度：{{ data.click }}</span>
                </div>
              </div>
            </div>
            <div class="details_price">
              <span class="details_price_content">¥{{ data.price }}</span>
            </div>
          </div>
        </div>
        <div class="base_info">
          <div class="base_info_title">
            <span></span>
            商品信息
          </div>
          <div class="base_info_content">
            <el-form :model="baseForm" :rules="baseRules" ref="baseForm" label-width="130px" class="baseForm">
              <el-form-item label="手机号：" prop="telphone" ref="telphone">
                <el-input placeholder="请输入手机号" v-model="baseForm.telphone"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="deal_process">
          <div class="deal_process_title">
            <span></span>
            交易流程说明
          </div>
          <div class="deal_process_content">
            <img src="@/assets/image/AllGame/onlineDeal/dealProcess.png" alt="">
          </div>
        </div>
        <div class="service">
          <div class="service_title">
            <span></span>
            订单价格
          </div>
          <div class="inclusionServiceOptions">
            <span></span>
            包赔服务选择
          </div>
          <div class="service_content">
            <div class="service_radio" v-for="(item,index) in indemnityService" :key="index">
              <div class="service_radio_option" :class="serviceRadio == index?'opitonsSelect':''"
                   @click="serviceRadioClick(index)">
                <div id="service_radio_title">
                  <span class="text">{{ item.title }}
                    <span class="tag" v-if="item.title === '找回包赔'">推荐</span>
                  </span>
                  <span class="price">{{ item.price === 0 ? '免费' : `价格：¥${item.price}` }}</span>
                </div>
                <div id="service_radio_content">{{ item.content }}</div>
                <div id="service_radio_option">
                  <div v-if="serviceRadio == index">
                    <img src="@/assets/image/AllGame/onlineDeal/serviceRadio.png" alt="">
                  </div>
                  <div v-if="serviceRadio != index"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="service_cost">
            <span></span>
            中介服务
            <div id="service_cost">
              价格：¥{{ agencyFee }}
            </div>
          </div>
        </div>
        <div class="totalPrice">
          <div class="label">合计：</div>
          <div class="price">
            <span>¥</span>
            {{ totalPrice }}
          </div>
        </div>
        <div class="step1Btn">
          <div id="step1Btn" @click="step1Btn('baseForm')">提交订单</div>
        </div>
      </div>
      <div class="step2" v-if="active===1">
        <div class="countDown">
          <div id="countDown">
            <div class="countDown_img">
              <img src="@/assets/image/AllGame/onlineDeal/success.png" alt="">
            </div>
            <div class="countDown_content">
              <div v-if="!countDown">
                <div class="countDown_content_top">订单提交成功！请完成支付～</div>
                <div class="countDown_content_bot">支付时间剩余
                  <count-down class="count_down" :endTime="1" :start-time="orderInfo.add_time"
                              @countDownChange="countDownChange"></count-down>
                  <span v-if="countDown" class="count_down">支付已超时！</span>
                </div>
              </div>
              <div v-else>
                <div class="countDown_content_top">支付订单超时,系统已为您自动取消订单~</div>
                <div class="countDown_content_bot">继续购买请重新下单</div>
              </div>


            </div>
          </div>
          <div v-if="countDown" class="step2Btn timeoutBtn">
            <div class="step2Btn_confirm" @click="step2BtnCancel">重新下单</div>
            <router-link class="step2Btn_cancel" :to="{name:'Home'}">返回首页</router-link>
          </div>
        </div>
        <div class="order_info">
          <div class="order_info_title">
            <span></span>
            订单详情
          </div>
          <div class="order_info_content">
            <el-table
              :data="ordertTableData"
              :cell-class-name="addOTdClass"
              border
              style="width: 100%">
              <el-table-column label="订单详情" class-name="OTD_info_title">
                <el-table-column
                  prop="productName"
                  align="center"
                  label-class-name="OTD_Info_label"
                  label="商品名称">
                </el-table-column>
                <el-table-column
                  prop="productType"
                  align="center"
                  label-class-name="OTD_Info_label"
                  label="商品类型">
                </el-table-column>
                <el-table-column
                  prop="productZone"
                  align="center"
                  label-class-name="OTD_Info_label"
                  label="商品区服">
                </el-table-column>
                <el-table-column
                  prop="orderDate"
                  align="center"
                  label-class-name="OTD_Info_label"
                  label="下单时间">
                </el-table-column>
                <el-table-column
                  prop="price"
                  align="center"
                  label-class-name="OTD_Info_label"
                  label="价格">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="pay_info">
          <div class="pay_info_title">
            <span></span>
            支付详情
          </div>
          <div class="pay_info_content">
            <div>
              <div class="pay_info_order">
                订单号：
                <span>{{ orderInfo.order_no }}</span>
              </div>
              <div class="pay_info_cost">
                <div class="PI_product_cost PI_cost">
                  <span>商品金额：</span>
                  ¥{{ orderInfo.price }}
                </div>
                <div class="PI_service_cost PI_cost">
                  <span>中介费：</span>
                  ¥{{ orderInfo.agency_fee }}
                </div>
                <div class="PI_BP_cost PI_cost">
                  <span>包赔费：</span>
                  ¥{{ orderInfo.indemnity_fee }}
                </div>
              </div>
              <div class="pay_info_total">
                最终应付总额：
                <span>¥{{ orderInfo.amount }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!countDown" class="pay_method">
          <div class="pay_method_title">
            <span></span>
            支付方式
          </div>
          <div class="pay_method_content">
            <div class="PM_option online_option" @click="payMethod = '1'">
              <img src="@/assets/image/AllGame/onlineDeal/AlipayLogo.png" alt="">
              <div>支付宝支付</div>
              <img class="PM_option_selected" v-if="payMethod === '1'"
                   src="@/assets/image/AllGame/onlineDeal/AlipayActive.png" alt="">
            </div>
          </div>
        </div>
        <div v-if="!countDown" class="step2Btn">
          <div class="step2Btn_confirm" @click="step2BtnConfirm">确认支付</div>
          <div class="step2Btn_cancel" @click="cancelOrderShow = true">取消订单</div>
        </div>

      </div>
      <div class="step3" v-if="active===2">
        <div class="paySuccess">
          <div class="paySuccess_img">
            <img src="@/assets/image/AllGame/onlineDeal/success.png" alt="">
          </div>
          <div class="paySuccess_text">
            <div class="paySuccess_title">订单支付成功！</div>
            <div class="paySuccess_content">请尽快联系客服获取您的商品～</div>
          </div>
        </div>
        <div class="voucher">
          <div class="orderNumber">
            <div class="label">
              订单号：
            </div>
            <div class="value">
              {{ orderInfo.order_no }}
            </div>
          </div>
          <div class="priceBox">
            <div class="priceItem">
              <div class="label">商品金额：</div>
              <div class="value">¥{{ orderInfo.price }}</div>
            </div>
            <div class="priceItem" style="margin: 0 20px">
              <div class="label">包赔费：</div>
              <div class="value">¥{{ orderInfo.indemnity_fee }}</div>
            </div>
            <div class="priceItem">
              <div class="label">中介费：</div>
              <div class="value">¥{{ orderInfo.agency_fee }}</div>
            </div>
          </div>
          <div class="totalPrice">
            <div class="label">
              实付总额：
            </div>
            <div class="value">
              ¥{{ orderInfo.amount }}
            </div>
          </div>
          <div class="payInfo">
            <div class="payTime">
              <div class="label">支付时间：</div>
              <div class="value">{{ orderInfo.deal_time|moments }}</div>
            </div>
            <div class="paymentMethod">
              <div class="label">支付方式：</div>
              <div class="value">支付宝</div>
            </div>
          </div>

        </div>
        <div class="step3Btn">
          <div class="btnGroup">
            <a class="step3Btn_service">联系客服</a>
            <router-link class="step3Btn_back" :to="{name:'Home'}">返回首页</router-link>
          </div>
          <div class="tips">
            <div class="tip">
              请尽快联系客服获取您的商品～
            </div>
            <span></span>
            <div class="checkOrder">
              查看订单详情
            </div>
          </div>

        </div>
      </div>
    </div>
    <my-dialog :show="cancelOrderShow" title="注意" text="取消订单后将清除下单数据，是否确认取消？" class="cancelOrder">
      <div class="btnGroup" slot="btn">
        <div class="cancel" @click="cancelOrderShow = false">取消</div>
        <div class="confirm" @click="step2BtnCancel">确认</div>
      </div>
    </my-dialog>
    <div class="ali_form" v-show="false" v-html="aliForm"></div>
  </div>
</template>

<script>

import myDialog from "@/components/myDialog";
import countDown from "@/components/countDown"; //引入倒计时组件
import {apiProductInfo, apiuserInfo, apiOrderAdd, apiGameInfo, apiOrderPay, apiOrderInfo} from "@/request/API";

export default {
  name: '',
  components: {
    countDown,
    myDialog
  },
  data() {
    return {
      active: 0,
      //step1
      //游戏名称
      game_name: '',
      //游戏id
      game_id: this.$route.query.game_id,
      //账号id
      id: this.$route.query.id,
      //游戏费率
      gameInfo: {},
      //中介费用
      agencyFee: 0,
      //商品数据
      data: {},
      //填写信息
      baseForm: {
        telphone: '',
      },
      baseRules: {
        telphone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
      },
      //总价
      totalPrice: 0,
      //包赔服务
      serviceRadio: 0,
      //stpe2
      countDown: false,
      ordertTableData: [],
      //支付方式
      payMethod: '1',
      //包赔服务
      indemnityService: [
        {title: '无需包赔', price: 0, content: '账号出现安全问题平台不承担任何责任'},
        {title: '找回包赔', price: 0, content: '账号出现安全问题平台进行售后处理,如账号未追回,由平台全额赔付'},
      ],
      //取消订单弹窗
      cancelOrderShow: false,
      //订单详情
      orderInfo: {},
      aliForm: ''
    }
  },
  methods: {
    //初始化数据
    async onLoad() {
      await apiProductInfo({
        game_id: this.game_id,
        id: this.id,
      }).then(res => {
        this.indemnityService[1].price = res.data.price * 0.1
        this.data = res.data
      })
      await apiuserInfo({
        action: 'base'
      }).then(res => {
        this.baseForm.telphone = res.data.telphone
        this.baseForm.qq = res.data.qq
      })
      await apiGameInfo({
        id: this.game_id,
      }).then(res => {
        this.gameInfo = res.data
        this.agencyFee = this.data.price * (res.data.zhongjie_fee * 0.1)
        if (this.agencyFee > res.data.zhongjie_fee_max) {
          this.agencyFee = res.data.zhongjie_fee_max
        } else if (this.agencyFee < res.data.zhongjie_fee_min) {
          this.agencyFee = res.data.zhongjie_fee_min
        }
        this.totalPrice = this.data.price + this.agencyFee + this.indemnityService[this.serviceRadio].price
      })
      apiOrderInfo({
        game_id: this.game_id,
        product_id: this.id,
      }).then(res => {
        if (!res.data.order_no) {
          this.active = 0
        } else {
          this.orderInfo = res.data
          let category = []
          res.data.goods_category.forEach((item, index) => {
            category.push(item.value)
          })
          this.ordertTableData = [
            {
              productName: res.data.goods_name,
              productType: '账号',
              productZone: category.toString(),
              orderDate: res.data.add_date,
              price: res.data.price
            }
          ]
          if (res.data.order_status === 0) {
            this.active = 1
          } else {
            this.active = 2
          }
        }
      })

    },
    /**
     * 步骤一 包赔服务单选点击事件
     * @param index 包赔服务下标
     */
    serviceRadioClick(index) {
      this.serviceRadio = index
      this.totalPrice = this.data.price + this.agencyFee + this.indemnityService[this.serviceRadio].price
    },
    /**
     * 步骤一  提交订单
     */
    step1Btn(formName) {
      this.$refs[formName].validate((valid, object) => {
        if (valid) {
          apiOrderAdd({
            goods_type: 1,
            game_id: this.game_id,
            goods_id: this.id,
            type: this.serviceRadio
          }).then(res => {
            this.orderInfo = res.data
            let category = []
            res.data.goods_category.forEach((item, index) => {
              category.push(item.value)
            })
            this.ordertTableData = [
              {
                productName: res.data.goods_name,
                productType: '账号',
                productZone: category.toString(),
                orderDate: res.data.add_time,
                price: res.data.price
              }
            ]

            if (this.active++ >= 3) this.active = 0;
            document.body.scrollTop = document.documentElement.scrollTop = 0;
          })
        } else {
          this.scrollView(object)
        }

      })
    },
    /**
     * 表单验证失败时 滚动到第一个error位置
     * @param object 表单验证失败的对象
     */
    scrollView(object) {
      const key = Object.keys(object)
      let dom = this.$refs[key[0]]
      dom.$el.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    },
    /**
     * 步骤二 倒计时子组件修改countDown
     */
    countDownChange(countDown) {
      this.countDown = countDown
    },
    /**
     * 步骤二 order table 添加样式
     * @param row
     * @param column
     * @returns {string}
     */
    addOTdClass({row, column}) {
      return 'OTD_Info_content'
    },
    /**
     * 步骤二  确认支付
     */
    step2BtnConfirm() {
      apiOrderPay({
        id: this.orderInfo.id
      }).then(res => {
        console.log(res);
        // // document.querySelector("body").innerHTML = res.data;
        // // document.forms[0].submit();
        // let divForm = document.getElementsByTagName('divform')
        // if (divForm.length) {
        //   document.body.removeChild(divForm[0])
        // }
        // const div = document.createElement('divform')
        // //
        // div.innerHTML = res.data // res.data就是sb支付宝返回给你的form
        // document.body.appendChild(div)
        // // document.forms[0].setAttribute('target', '_blank') // 加了_blank可能出问题所以我注释了
        // document.getElementById('alipaysubmit').submit()


        document.querySelector('body').innerHTML = res.data;
        document.forms['alipaysubmit'].submit()
        //
        // if (this.active++ >= 3) this.active = 0;

      })
    },
    /**
     * 步骤二  取消订单
     */
    step2BtnCancel() {
      this.cancelOrderShow = false
      this.$router.go(-1)
    },
  },
  mounted() {
    this.onLoad()
  },
  watch: {},
  computed: {},


}
</script>


<style lang="less" scoped>
.online_deal {
  width: 1200px;

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;

      .Breadcrumb {
        font-size: 12px;
      }
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
      cursor: pointer;
    }
  }

  .content {
    padding: 40px 40px 95px;
    width: 1120px;
    background: #FFFFFF;

    .steps {
      display: flex;
      justify-content: center;
      margin: 0 0 40px 0;

      #steps {
        text-align: center;
        width: 600px;
        height: 102px;

        /deep/ .el-step__line-inner {
          display: none !important;
        }

        /deep/ .el-step__line {
          left: 0%;
          right: 0%;
          height: 2px;
        }

        /deep/ .is-finish > .el-step__line {
          background-color: #434ED6;
          top: 10px;
          height: 4px;
        }

        /deep/ .is-process > .el-step__line {
          background-color: #434ED6;
          top: 10px;
          height: 4px;
        }

        /deep/ .is-wait > .el-step__line {
          display: block;
        }

        /deep/ .is-process > .el-step__line {
          display: block;
        }

        /deep/ .el-step__icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border: none;
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #5B5B5B;
          background: #DDDDDD;
        }

        /deep/ .is-finish > .el-step__icon {
          background: #434ED6;
          color: #FFFFFF;
        }

        /deep/ .is-process > .el-step__icon {
          background: #434ED6;
          color: #FFFFFF;
        }

        /deep/ .el-step__title {
          margin: 10px 0 0 0;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #5B5B5B;
          line-height: 22px;
        }
      }
    }

    .step1 {
      .product_info {
        display: flex;
        flex-direction: column;
        padding: 0 0 22px;
        margin: 0 0 30px 0;
        border-bottom: 1px solid #E2E2E2;

        .product_info_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 20px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .product_info_content {
          height: 150px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          #product_info_content {
            display: flex;
            flex-direction: row;
            align-items: center;

            .details_img {
              width: 270px;
              height: 150px;
              margin-right: 20px;
              cursor: pointer;
              border-radius: 10px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
              }
            }

            .details_info {
              width: 668px;
              margin-right: 48px;

              .details_info_top {
                font-size: 16px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                margin-bottom: 10px;
                height: 66px;
                display: -webkit-box;
                display: -ms-grid;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 22px;
                overflow: hidden;

                span {
                  border: 1px solid #434ED6;
                  font-size: 14px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #434ED6;
                  border-radius: 15px;
                  padding: 0 17px 0 18px;
                  margin-right: 5px;
                }

                #game_list_details_content {
                  padding: 20px;
                  background: rgba(0, 0, 0, 0.8);
                  display: none;
                  position: absolute;
                  top: 110px;
                  left: 213px;
                  width: 685px;

                  #game_list_details_content_title {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 500;
                    color: #FCAD13;
                    line-height: 22px;
                    margin-bottom: 7px;
                  }

                  #game_list_details_content_detail {
                    //font-size: 14px;
                    //font-family: PingFangSC-Thin, PingFang SC;
                    //font-weight: 300;
                    //color: #5B5B5B;
                    //line-height: 20px;
                    //width: 1122px;

                    font-size: 14px;
                    font-family: PingFangSC-Thin, PingFang SC;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 20px;
                  }
                }
              }

              .details_info_mid {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 10px;

                span {
                  width: 33.33%;
                  font-size: 16px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #8F8F8F;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .details_info_bot {
                display: flex;
                flex-direction: row;

                span {
                  font-size: 16px;
                  font-family: PingFangSC-Light, PingFang SC;
                  font-weight: 400;
                  color: #8F8F8F;
                }

                .release_time {
                  margin-right: 100px;
                }

                .hot_pic {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                }
              }
            }
          }

          .details_price {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 200px;
            max-width: 260px;

            .details_price_title {
              width: 80px;
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              line-height: 22px;
            }

            .details_price_content {
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
            }

          }
        }
      }

      .base_info {
        display: flex;
        flex-direction: column;
        padding: 0 0 22px;
        margin: 0 0 30px 0;
        border-bottom: 1px solid #E2E2E2;

        .base_info_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 20px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .base_info_content {
          /deep/ .el-form-item__label {
            //padding: 0 30px 0 0;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
          }

          /deep/ .el-input__inner {
            width: 250px;
            height: 40px;
            background: #F4F4F4;
            border-radius: 4px;
            border: 1px solid #F0F0F0;
          }

          /deep/ .el-input__inner::-webkit-input-placeholder {
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #BFBFBF;
          }
        }
      }

      .deal_process {
        padding: 0 0 30px;
        margin: 0 0 30px 0;
        border-bottom: 1px solid #E2E2E2;

        .deal_process_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 20px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .deal_process_content {
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .service {
        padding: 0 0 30px;
        border-bottom: 1px solid #E2E2E2;

        .service_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 36px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .inclusionServiceOptions {
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
          margin: 0 0 20px 24px;

          span {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: #434ED6;
            margin: 0 10px 0 0;
          }

        }

        .service_content {
          margin: 0 0 0 24px;

          .service_radio {
            .service_radio_option {
              cursor: pointer;
              margin: 15px 0;
              width: 560px;
              height: 47px;
              background: #F8F8F8;
              border-radius: 5px;
              padding: 10px 20px;
              position: relative;

              #service_radio_title {
                display: flex;
                align-items: center;
                margin: 0 0 5px;

                .text {
                  width: 144px;
                  margin: 0 20px 0 0;
                  font-size: 16px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #000000;
                  display: flex;
                  align-items: center;

                  .tag {
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    padding: 1px 8px 2px;
                    background: #FCAD13;
                    border-radius: 5px 5px 5px 5px;
                    margin: 0 0 0 4px;
                  }
                }

                .price {
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #000000;
                }
              }

              #service_radio_content {
                font-size: 14px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 400;
                color: #7B7B7B;

              }

              #service_radio_option {
                width: 60px;
                display: flex;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 24px;

                div {
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  background: #FFFFFF;
                  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1),
                    inset -5px -5px 11px #ffffff;
                }
              }
            }

            .service_radio_option:hover {
              background: #FFFBED;
            }

            .opitonsSelect {
              width: 558px;
              height: 45px;
              background: #FFFBED;
              border: 1px solid #FCAD13;
            }
          }
        }

        .service_cost {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 40px 0 0 24px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #000000;

          span {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: #434ED6;
            margin: 0 10px 0 0;
          }

          #service_cost {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #000000;
            margin: 0 0 0 40px;
          }
        }
      }

      .totalPrice {
        display: flex;
        align-items: center;
        padding: 0 0 20px;
        border-bottom: 1px solid #E2E2E2;
        margin: 20px 0 0;

        .label {
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin: 0 9px 0 0;
        }

        .price {
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #FC4A00;

          span {
            font-size: 20px;
          }
        }

      }

      .step1Btn {
        display: flex;
        justify-content: center;

        #step1Btn {
          cursor: pointer;
          margin: 71px 0 0 0;
          width: 300px;
          height: 50px;
          text-align: center;
          background: #434ED6;
          border-radius: 30px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 50px;
        }
      }
    }

    .step2 {
      .countDown {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 0 61px 0;

        #countDown {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 54px;

          .countDown_img {
            margin: 0 20px 0 0;
          }

          .countDown_content {
            .countDown_content_top {
              font-size: 20px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #434ED6;
              line-height: 28px;
            }

            .countDown_content_bot {
              font-size: 16px;
              font-family: PingFangSC-Thin, PingFang SC;
              font-weight: 100;
              color: #5B5B5B;
              line-height: 22px;

              .count_down {
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #434ED6;
                line-height: 25px;
                margin: 0 0 0 10px;
              }
            }
          }
        }
      }

      .order_info {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px 0;

        .order_info_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 16px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .order_info_content {
          /deep/ .OTD_info_title {
            background: #FAFAFF;
            padding: 17px 0;

            .cell {
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #4D4D4D;
              padding: 0 0 0 20px;
            }
          }

          /deep/ .OTD_Info_label, /deep/ .OTD_Info_content {
            background: #FFFFFF;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #909398;
            line-height: 14px;
            padding: 0;

            .cell {
              padding: 17px 17px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .pay_info {
        display: flex;
        flex-direction: column;
        margin: 0 0 30px;
        padding: 0 0 29px;
        border-bottom: 1px solid #E2E2E2;

        .pay_info_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 16px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .pay_info_content {
          display: flex;
          flex-direction: row;

          .pay_info_order {
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #4D4D4D;
            line-height: 20px;
            margin: 0 0 10px 24px;
          }

          .pay_info_cost {
            margin: 0 0 0 24px;
            padding: 20px 0;
            border-bottom: 1px solid #ECECEC;
            border-top: 1px solid #ECECEC;

            .PI_cost {
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #909398;
              line-height: 20px;

              span {
                display: inline-block;
                width: 120px;
                color: #4D4D4D;
              }
            }

            .PI_service_cost {
              margin: 10px 0;
            }
          }

          .pay_info_total {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 276px;
            margin: 10px 0 0 24px;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #4D4D4D;
            line-height: 20px;

            span {
              margin: 0 0 0 22px;
              display: block;
              font-size: 28px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #FC4A00;
              line-height: 40px;
            }
          }
        }
      }

      .pay_method {
        display: flex;
        flex-direction: column;
        margin: 0 0 80px 0;
        padding: 0 0 30px;
        border-bottom: 1px solid #E2E2E2;

        .pay_method_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #000000;
          line-height: 28px;
          display: flex;
          align-items: center;
          flex-direction: row;
          margin: 0 0 16px 0;

          span {
            width: 10px;
            height: 20px;
            background: #000000;
            margin: 0 14px 0 0;
          }
        }

        .pay_method_content {
          display: flex;
          flex-direction: row;
          margin: 0 0 0 24px;

          .PM_option {
            width: 400px;
            height: 80px;
            border-radius: 10px;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #8F8F8F;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            img {
              margin: 0 20px 0 0;
            }

            .PM_option_selected {
              margin: 0;
              position: absolute;
              top: 0px;
              left: 360px;
            }
          }

          .online_option {
            border: 1px solid #06B4FD;
            margin: 0 50px 0 0;
          }

          .offline_option {
            border: 1px solid #07C160;
          }
        }
      }

      .step2Btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        div, a {
          width: 300px;
          height: 50px;
          border-radius: 30px;
          text-align: center;
          line-height: 50px;
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        .step2Btn_confirm {
          background: #434ED6;
          color: #FFFFFF;
          margin: 0 20px 0 0;
        }

        .step2Btn_cancel {
          width: 298px;
          height: 48px;
          border: 1px solid #000000;
        }
      }
    }

    .step3 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .paySuccess {
        display: flex;
        flex-direction: row;
        margin: 0 0 40px 0;

        .paySuccess_img {
          margin: 0 14px 0 0;
        }

        .paySuccess_text {
          .paySuccess_title {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #434ED6;
            line-height: 28px;
            margin: 0 0 2px;
          }

          .paySuccess_content {
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #8F8F8F;
            line-height: 22px;
          }
        }
      }

      .voucher {
        min-width: 585px;
        padding: 15px 0;
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        margin: 0 0 40px;

        div {
          display: flex;
          align-items: center;

          .label {
            min-width: 80px;
            margin: 0 10px 0 0;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #8F8F8F;
          }

          .value {
            font-size: 18px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
          }
        }

        .orderNumber {
          margin: 0 0 10px;
          padding: 0 56px;

        }

        .priceBox {
          padding: 0 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .priceItem {
            display: flex;
            align-items: center;

            .label {
              min-width: 80px;
              margin: 0 10px 0 0;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #8F8F8F;
            }

            .value {
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
            }
          }
        }

        .totalPrice {
          padding: 15px 56px;
          margin: 15px 0;
          border-top: 1px solid #EEEEEE;
          border-bottom: 1px solid #EEEEEE;

          .value {
            font-size: 24px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FC4A00;
          }
        }

        .payInfo {
          padding: 0 56px;
          display: flex;
          align-items: center;

          .payTime, .paymentMethod {
            display: flex;
            align-items: center;

            div {
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #8F8F8F;
            }
          }

          .payTime {
            margin: 0 100px 0 0;
          }
        }
      }

      .step3Btn {
        .btnGroup {
          display: flex;
          align-items: center;
          margin: 0 0 10px;

          div, a {
            margin: 0 5px;
            display: block;
            width: 188px;
            height: 42px;
            border-radius: 5px;
            line-height: 42px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }

          .step3Btn_service {
            background: #434ED6;
          }

          .step3Btn_back {
            width: 186px;
            height: 40px;
            color: #000000;
            border: 1px solid #000000;
          }
        }

        .tips {
          display: flex;
          align-items: center;
          justify-content: center;

          .tip {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }

          span {
            width: 1px;
            height: 15px;
            background: linear-gradient(184deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
            display: block;
            margin: 0 10px;
          }

          .checkOrder {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #434ED6;
            cursor: pointer;
          }
        }


      }
    }
  }

  .cancelOrder {
    .btnGroup {
      display: flex;
      align-items: center;

      div {
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 28px 28px 28px 28px;
        opacity: 1;
        border: 1px solid #434ED6;
        margin: 0 7px;
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #434ED6;
        cursor: pointer;
      }

      /deep/ .confirm {
        background: #434ED6;
        color: #FFFFFF;
      }
    }
  }
}

</style>
